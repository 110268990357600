import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"

const PageHeader = ({
  image,
  imageSm,
  imageLg,
  title,
  subTitle,
  overlay,
  formLocation,
}) => {
  let overlayTransparency, headerImage, contactButton

  switch (overlay) {
    case "dark":
      overlayTransparency = "dark-overlay"
      break
    case "darker":
      overlayTransparency = "darker-overlay"
      break
    default:
      overlayTransparency = "dark-overlay"
      break
  }

  if (image) {
    headerImage = (
      <GatsbyImage
        image={image}
        alt=""
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
    )
  } else if (imageSm && imageLg) {
    headerImage = (
      <>
        <GatsbyImage
          image={imageSm}
          alt=""
          className="d-sm-block d-md-none"
          loading="eager"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <GatsbyImage
          image={imageLg}
          alt=""
          className="d-none d-md-block"
          loading="eager"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </>
    )
  }

  if (formLocation === "onPage") {
    contactButton = (
      <ScrollLink
        to="contact"
        spy={true}
        smooth={true}
        duration={500}
        offset={-100}
      >
        <button className="btn btn-info text-uppercase">Work with us</button>
      </ScrollLink>
    )
  } else {
    contactButton = (
      <Link to="/contact">
        <button className="btn btn-info text-uppercase">Work with us</button>
      </Link>
    )
  }

  return (
    <header id="page-header" className="position-relative pt-5 mb-5 shadow">
      <div className="container">
        {headerImage}
        <div className={overlayTransparency} />

        <div className="row page-header align-items-center">
          <div className="col-md-10 col-lg-7">
            <h6 className="text-primary text-uppercase mb-2">{title}</h6>
            <h1 className="text-white mb-4">{subTitle}</h1>
            {contactButton}
          </div>
        </div>
      </div>
    </header>
  )
}

export default PageHeader
