import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHeader from "../components/common/PageHeader"

const ApproachPage = () => (
  <StaticQuery
    query={graphql`
      {
        privacyPolicy: file(relativePath: { eq: "privacy-policy.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Seo
          title="Privacy Policy"
          keywords={[`privacy policy`]}
          description="Tactic Apps Privacy Policy for use of cookies."
        />
        <PageHeader
          image={data.privacyPolicy.childImageSharp.gatsbyImageData}
          title="Privacy Policy"
          subTitle="Learn how we use cookies to enhance your experience."
        />
        <main>
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-9 m-auto text-center">
                <h2 className="mb-5">Privacy Policy for Tactic Apps</h2>
              </div>
              <div className="col-lg-11 m-auto">
                <p className="text-muted">
                  At Tactic Apps, accessible from https://www.tacticapps.com,
                  one of our main priorities is the privacy of our visitors.
                  This Privacy Policy document contains types of information
                  that is collected and recorded by Tactic Apps and how we use
                  it.
                </p>

                <p className="text-muted">
                  If you have additional questions or require more information
                  about our Privacy Policy, do not hesitate to contact us
                  through email at support@tacticapps.com
                </p>

                <h4 className="mt-5 mb-3">Log Files</h4>

                <p className="text-muted">
                  Tactic Apps follows a standard procedure of using log files.
                  These files log visitors when they visit websites. All hosting
                  companies do this and a part of hosting services' analytics.
                  The information collected by log files include internet
                  protocol (IP) addresses, browser type, Internet Service
                  Provider (ISP), date and time stamp, referring/exit pages, and
                  possibly the number of clicks. These are not linked to any
                  information that is personally identifiable. The purpose of
                  the information is for analyzing trends, administering the
                  site, tracking users' movement on the website, and gathering
                  demographic information.
                </p>

                <h4 className="mt-5 mb-3">Cookies and Web Beacons</h4>

                <p className="text-muted">
                  Like any other website, Tactic Apps uses 'cookies'. These
                  cookies are used to store information including visitors'
                  preferences, and the pages on the website that the visitor
                  accessed or visited. The information is used to optimize the
                  users' experience by customizing our web page content based on
                  visitors' browser type and/or other information.
                </p>

                <h4 className="mt-5 mb-3">Google DoubleClick DART Cookie</h4>

                <p className="text-muted">
                  Google is one of a third-party vendor on our site. It also
                  uses cookies, known as DART cookies, to serve ads to our site
                  visitors based upon their visit to www.tacticapps.com and
                  other sites on the internet. However, visitors may choose to
                  decline the use of DART cookies by visiting the Google ad and
                  content network Privacy Policy at the following URL –{" "}
                  <a href="https://policies.google.com/technologies/ads">
                    https://policies.google.com/technologies/ads
                  </a>
                </p>

                <h4 className="mt-5 mb-3">Privacy Policies</h4>

                <p className="text-muted">
                  You may consult this list to find the Privacy Policy for each
                  of the advertising partners of Tactic Apps.
                </p>

                <p className="text-muted">
                  Third-party ad servers or ad networks uses technologies like
                  cookies, JavaScript, or Web Beacons that are used in their
                  respective advertisements and links that appear on Tactic
                  Apps, which are sent directly to users' browser. They
                  automatically receive your IP address when this occurs. These
                  technologies are used to measure the effectiveness of their
                  advertising campaigns and/or to personalize the advertising
                  content that you see on websites that you visit.
                </p>

                <p className="text-muted">
                  Note that Tactic Apps has no access to or control over these
                  cookies that are used by third-party advertisers.
                </p>

                <h4 className="mt-5 mb-3">Third Party Privacy Policies</h4>

                <p className="text-muted">
                  Tactic Apps's Privacy Policy does not apply to other
                  advertisers or websites. Thus, we are advising you to consult
                  the respective Privacy Policies of these third-party ad
                  servers for more detailed information. It may include their
                  practices and instructions about how to opt-out of certain
                  options. You may find a complete list of these Privacy
                  Policies and their links here: Privacy Policy Links.
                </p>

                <p className="text-muted">
                  You can choose to disable cookies through your individual
                  browser options. To know more detailed information about
                  cookie management with specific web browsers, it can be found
                  at the browsers' respective websites. What Are Cookies?
                </p>

                <h4 className="mt-5 mb-3">Children's Information</h4>

                <p className="text-muted">
                  Another part of our priority is adding protection for children
                  while using the internet. We encourage parents and guardians
                  to observe, participate in, and/or monitor and guide their
                  online activity.
                </p>

                <p className="text-muted">
                  Tactic Apps does not knowingly collect any Personal
                  Identifiable Information from children under the age of 13. If
                  you think that your child provided this kind of information on
                  our website, we strongly encourage you to contact us
                  immediately and we will do our best efforts to promptly remove
                  such information from our records.
                </p>

                <h4 className="mt-5 mb-3">Online Privacy Policy Only</h4>

                <p className="text-muted">
                  This Privacy Policy applies only to our online activities and
                  is valid for visitors to our website with regards to the
                  information that they shared and/or collect in Tactic Apps.
                  This policy is not applicable to any information collected
                  offline or via channels other than this website.
                </p>

                <h4 className="mt-5 mb-3">Consent</h4>

                <p className="text-muted">
                  By using our website, you hereby consent to our Privacy Policy
                  and agree to its Terms and Conditions.
                </p>
              </div>
            </div>
          </div>
        </main>
      </Layout>
    )}
  />
)

export default ApproachPage
